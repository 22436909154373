<template>
  <div class="wrapper">
    <div>
      <CToaster :autohide="3000">
        <div v-for="info in infoList" :key="info.message">
          <CToast :show="true" :header="info.header" :color="info.color">
            {{ info.message }}.
          </CToast>
        </div>
      </CToaster>
      <CRow>
        <CCol>
          <WidgetsVehicleInfo
           :stock="obj" 
          ></WidgetsVehicleInfo>
        </CCol>
      </CRow>
      
      <CRow>
        <CCol sm="12">
          <CCardFooter>
            <CButton color="primary" @click="goBack">
              Go Back
            </CButton>
          </CCardFooter>
        </CCol>
      </CRow>
    </div>
  </div>
  
</template>

<script>
import StockApi from "../../lib/stockApi";
import WidgetsVehicleInfo from "../widgets/WidgetsVehicleInfo"
import moment from "moment";

export default {
  name: "Vehicle",
  components:{
    WidgetsVehicleInfo
  },
  data: () => ({
    infoList: [],
    api: new StockApi(),
    obj: {
      vehicle: {
        brandName: "",
        modelName: "",
        chasisNo: "",
        engineCapacity: "",
        vehicleType: "",
        month: "",
        year: "",
        color: "",
        vehiclePhotoList: [], // Ensure this list is included
      },
      purchase: {
        supplier: {
          name: "",
        },
        supplierCurrency: "",
        vehiclePriceSupplierCurrency: "",
        vehiclePriceLocalCurrency: "",
      },
      import: {
        shipName: "",
        estimatedDepartureDate: "",
        estimatedArrivalDate: "",
        dateOfBillOfLading: "",
        bolDocuments: [],
      },
      clearance: { // Added clearance object
        approvedPermitNo: "",
        k8DocumentNo: "",
        k1DocumentNo: "",
        k8Documents: [],
      },
      pricing: {
        recommendedSalePrice: "",
      },
      stockStatusHistories: [{
        stockStatus: {
          name: ""
        }
      }]
    },
    vehicleTypes: [], // This will be populated with dropdown options
    monthOptions: [
      { value: "", text: "Select Month" },
      { value: "01", text: "January" },
      { value: "02", text: "February" },
      { value: "03", text: "March" },
      { value: "04", text: "April" },
      { value: "05", text: "May" },
      { value: "06", text: "June" },
      { value: "07", text: "July" },
      { value: "08", text: "August" },
      { value: "09", text: "September" },
      { value: "10", text: "October" },
      { value: "11", text: "November" },
      { value: "12", text: "December" },
    ],
    yearOptions: [], // Populate with years or use a range
    bolDocumentFieldList: [
      { key: "document_link", label: "Document Link" },
      { key: "remove", label: "Remove" },
    ],
    k8DocumentFieldList: [
      { key: "document_link", label: "Document Link" },
      { key: "remove", label: "Remove" },
    ],
    loading: false,
  }),
  mounted() {
    this.resetObj();
    //this.fetchVehicleTypes();
    //this.fetchYearOptions();
  },
  computed: {
    computedBolDocumentList() {
      return this.obj.import.bolDocuments.map((doc) => ({
        documentName: doc.name,
        documentUrl: this.getDocumentUrl(doc),
      }));
    },
    computedK8DocumentList() {
      return this.obj.clearance.k8Documents.map((doc) => ({
        documentName: doc.name,
        documentUrl: this.getDocumentUrl(doc),
      }));
    },
    computeEstimateDateOfDeparture() {
      return this.obj.import.estimatedDepartureDate
        ? moment(this.obj.import.estimatedDepartureDate).format(
            "YYYY-MM-DDTHH:mm"
          )
        : "";
    },
    computeEstimateDateOfArrival() {
      return this.obj.import.estimatedArrivalDate
        ? moment(this.obj.import.estimatedArrivalDate).format("YYYY-MM-DDTHH:mm")
        : "";
    },
    computeDateOfBillOfLading() {
      return this.obj.import.dateOfBillOfLading
        ? moment(this.obj.import.dateOfBillOfLading).format("YYYY-MM-DDTHH:mm")
        : "";
    },
  },
  
  methods: {
    getDisplayDateTime(item) {
      return moment(item).format("DD/MM/YYYY HH:mm");
    },
    getImageUrl(item) {
      console.log(item);
      if (!item.vehicle || !item.vehicle.vehiclePhotoList || item.vehicle.vehiclePhotoList.length === 0) return "";
      const imageUrl = apiUrl +  `Documents/File/${item.vehicle.vehiclePhotoList[0].documentId}`;
      return imageUrl;
    },
    resetObj() {
      if (this.$route.params.id) {
        this.api
          .get(this.$route.params.id)
          .then((response) => {
            this.obj = response.result;
            console.log(this.obj);
          })
          .catch(({ data }) => {
            this.toast("Error", this.getErrorMessage(data), "danger");
          });
      }
    },
    // fetchVehicleTypes() {
    //   // Fetch vehicle types and set `vehicleTypes`
    //   this.api.getVehicleTypes().then((response) => {
    //     this.vehicleTypes = response.result.map((type) => ({
    //       value: type.id,
    //       text: type.name,
    //     }));
    //   });
    // },
    // fetchYearOptions() {
    //   // Populate year options, e.g., from the current year to a certain range
    //   const currentYear = new Date().getFullYear();
    //   this.yearOptions = Array.from({ length: 10 }, (_, i) => currentYear - i).map(
    //     (year) => ({ value: year.toString(), text: year.toString() })
    //   );
    // },
    setEstimateDateOfDeparture(event) {
      this.obj.import.estimatedDepartureDate = event.target.value;
    },
    setEstimateDateOfArrival(event) {
      this.obj.import.estimatedArrivalDate = event.target.value;
    },
    setDateOfBillOfLading(event) {
      this.obj.import.dateOfBillOfLading = event.target.value;
    },
    getDocumentUrl(doc) {
      return `your-api-url/documents/${doc.id}`;
    },
    removeBolDocumentConfirmation(item) {
      // Implement the logic to confirm and remove the BOL document
    },
    removeK8DocumentConfirmation(item) {
      // Implement the logic to confirm and remove the K8 document
    },
    bol_uploaded(uploadedDoc) {
      // Implement the logic to handle the uploaded document
      this.obj.import.bolDocuments.push(uploadedDoc);
    },
    k8_uploaded(uploadedDoc) {
      // Implement the logic to handle the uploaded K8 document
      this.obj.clearance.k8Documents.push(uploadedDoc);
    },
    onSearchSupplier() {
      // Implement search supplier functionality
    },
    goBack() {
      this.$router.go(-1);
    },
    toast(header, message, color) {
      this.infoList.push({ header, message, color });
    },
    getErrorMessage(data) {
      // Implement a helper function to extract error message from response data
      return data.message || "An error occurred";
    },
    getImage(item) {
      if (item.vehicle == null) return "";
      if (item.vehicle.vehiclePhotoList == null) return "";
      if (item.vehicle.vehiclePhotoList.length == 0) return "";
      var url = "";
      url = apiUrl + "Documents/File/" + item.vehicle.vehiclePhotoList[0].documentId;
      return url;
    },
  },
};
</script>
<style scoped>
.img-size {
  width: 500px; 
  height: 300px; 
  object-fit: cover; 
  margin: 0 auto; 
  display: block; 
}
</style>