<template>
  <div class="vehicle-info-container">
    <!-- Header Section with Brand, Model, and Price -->
    <CRow class="header-section">
      <CCol sm="12" class="text-center">
        <CCard>
          <CCardBody>
            <h1>{{ getBrandName() }} {{ getModelName() }}</h1>
            <h3 class="price">
              {{ formatPrice(stock.pricing.recommendedSalePrice) }}
            </h3>
            <h3>COLOR: {{ stock.vehicle.color }}</h3>
            <strong>DESCRIPTION:</strong>
            <p v-html="formatAddress(stock.vehicle.description)"></p>
            <p>
              <a
                :href="stock.vehicle.externalLink"
                rel="noopener noreferrer"
                target="_blank"
                class="card-header-action"
              >
                <small class="text-muted">More info</small>
              </a>
            </p>
            <p>STOCK NO: <strong>{{ stock.stockNo }}</strong> </p>

          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- Vehicle Images Section with Thumbnails -->
    <CRow>
      <CCol>
        <CCard>
          <CCardBody>
            <CRow>
              <CCol
                v-for="(item, index) in stock.vehicle.vehiclePhotoList"
                :key="item.id"
                xs="12"
                sm="6"
                md="4"
                lg="3"
                xl="2"
                class="d-flex justify-content-center position-relative mb-4"
              >
                <div>
                  <img
                    :src="getImageUrl(item)"
                    thumbnail
                    class="img-fluid"
                    style="max-width: 100%; cursor: pointer"
                    @click="showImageModal(item)"
                  />
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <!-- Modal for full-size image -->

    <!-- <CRow class="image-section">
      <CCol sm="12">
        <CCard>
          <CCardBody>
            <div class="thumbnail-gallery">
              <div
                v-for="(photo, index) in stock.vehicle.vehiclePhotoList"
                :key="index"
                class="thumbnail-item"
              >
                <img
                  :src="getImageUrl(photo)"
                  @click="showImageModal(photo)"
                  class="thumbnail-image"
                  alt="Vehicle Image"
                />
              </div>
              <div v-if="stock.vehicle.vehiclePhotoList.length === 0">
                <p>No images available.</p>
              </div>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow> -->

    <!-- Vehicle Images Section -->
    <!-- <CRow class="image-section">
      <CCol sm="12">
        <CCard>
          <CCardBody>
            <CCarousel arrows indicators animate>
              <CCarouselItem
                v-for="(photo, index) in stock.vehicle.vehiclePhotoList"
                :key="index"
                :captionHeader="'Image ' + (index + 1)"
                :image="getImageUrl(photo)"
                captionText="Vehicle Image"
              />
            </CCarousel>
            <div v-if="stock.vehicle.vehiclePhotoList.length === 0">
              <p>No images available.</p>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow> -->

    <!-- Vehicle Information Section -->
    <CRow class="info-section">
      <CCol sm="6">
        <CCard>
          <CCardHeader> <strong>Vehicle</strong> Information </CCardHeader>
          <CCardBody>
            <CForm>
              <CInput
                label="Brand"
                horizontal
                readonly
                :value="getBrandName()"
              />
              <CInput
                label="Model"
                horizontal
                readonly
                :value="getModelName()"
              />
              <CInput
                label="Chassis No"
                horizontal
                readonly
                v-model="stock.vehicle.chasisNo"
              />
              <CInput
                label="Engine Capacity"
                horizontal
                readonly
                v-model="stock.vehicle.engineCapacity"
              />
              <CInput
                label="Color"
                horizontal
                readonly
                v-model="stock.vehicle.color"
              />
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol sm="6">
        <CCard>
          <CCardHeader> <strong>Vehicle</strong> Details </CCardHeader>
          <CCardBody>
            <CForm>
              <CInput
                label="Vehicle Type"
                horizontal
                readonly
                :value="getVehicleType()"
              />
              <CInput
                label="Year"
                horizontal
                readonly
                :value="getVehicleYear()"
              />
              <CInput
                label="Month"
                horizontal
                readonly
                :value="getVehicleMonth()"
              />
              <!-- <CInput
                label="Purchase Supplier"
                horizontal
                readonly
                v-model="stock.purchase.supplier.name"
              />
              <CInput
                label="Supplier Contact"
                horizontal
                readonly
                v-model="stock.purchase.supplier.contactPersonName"
              /> -->
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- Pricing Information Section -->
    <CRow class="pricing-section">
      <CCol sm="12">
        <CCard>
          <CCardHeader> <strong>Pricing</strong> Information </CCardHeader>
          <CCardBody>
            <CForm>
              <CInput
                label="Recommended Sale Price"
                horizontal
                readonly
                v-model="stock.pricing.recommendedSalePrice"
              />
              <CInput
                label="Minimum Sale Price"
                horizontal
                readonly
                v-model="stock.pricing.minimumSalePrice"
              />
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- Remarks Section -->
    <CRow class="remarks-section">
      <CCol sm="12">
        <CCard>
          <CCardHeader> <strong>Remarks</strong> </CCardHeader>
          <CCardBody>
            <div v-if="stock.remarksList && stock.remarksList.length > 0">
              <ul>
                <li v-for="remark in stock.remarksList" :key="remark.id">
                  <strong>{{ remark.profile.fullName }}:</strong>
                  {{ remark.description }}
                  <em>({{ new Date(remark.dateTime).toLocaleString() }})</em>
                </li>
              </ul>
            </div>
            <div v-else>
              <p>No remarks available.</p>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CModal
      title="Maximized Image"
      :show.sync="isImageModalVisible"
      size="lg"
      @close="isImageModalVisible = false"
    >
      <CImg :src="currentImageUrl" class="maximized-image" />
    </CModal>
  </div>
</template>

<script>
export default {
  name: "WidgetsVehicleInfo",
  props: {
    stock: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isImageModalVisible: false,
      currentImageUrl: null,
    };
  },
  methods: {
    formatAddress(address) {
      try {
        return address.replace(/\n/g, "<br />");
      } catch (error) {
        return address;
        
      }
    },
    showImageModal(photo) {
      this.currentImageUrl = this.getImageUrl(photo);
      this.isImageModalVisible = true;
    },
    getVehicleMonth() {
      try {
        return stock.vehicle.month;
      } catch (error) {
        return "N/A";
      }
    },
    getVehicleYear() {
      try {
        return stock.vehicle.year;
      } catch (error) {
        return "N/A";
      }
    },
    getVehicleType() {
      try {
        return stock.vehicle.vehicleType.name;
      } catch (error) {
        return "N/A";
      }
    },
    getBrandName() {
      try {
        return this.stock.vehicle.brand.name;
      } catch (error) {
        return "Unknown";
      }
    },
    getModelName() {
      try {
        return this.stock.vehicle.model.name;
      } catch (error) {
        return "Unknown";
      }
    },
    getLatestStockStatus() {
      try {
        return this.stock.latestStockStatus.stockStatus.name;
      } catch (error) {
        return "Unknown";
      }
    },
    getImageUrl(item) {
      const imageUrl = `${apiUrl}Documents/File/${item.documentId}`;
      return imageUrl;
    },
    formatPrice(price) {
      return new Intl.NumberFormat("en-MY", {
        style: "currency",
        currency: "MYR",
      }).format(price);
    },
  },
};
</script>

<style scoped>
.maximized-image {
  width: 100%;
  height: auto;
  max-width: 100%;
  border-radius: 8px; /* Optional: Add some border radius for aesthetics */
}

.vehicle-info-container {
  padding: 20px;
}

.header-section {
  margin-bottom: 20px;
}

.header-section h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.header-section .price {
  font-size: 2rem;
  color: #28a745;
}

.image-section {
  margin-bottom: 20px;
}

.info-section {
  margin-bottom: 20px;
}

.pricing-section {
  margin-bottom: 20px;
}

.remarks-section {
  margin-bottom: 20px;
}

.vehicle-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.ccard-header {
  background-color: #007bff;
  color: #fff;
}

.ccard-body {
  background-color: #f8f9fa;
}

.cinput {
  margin-bottom: 15px;
}
</style>
